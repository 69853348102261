<template>
  <span class="syndications-popper__container">
    <slot :toggle-open="toggleOpen" />
  </span>
  <Popper
    v-model="state.isOpen"
    v-bind="popperAttrs"
  >
    <div
      class="tw-h-full tw-max-h-[50vh] tw-overflow-hidden tw-overflow-y-auto tw-rounded-md tw-bg-white tw-drop-shadow-md"
    >
      <table
        class="tw-w-full tw-border-collapse tw-text-center tw-text-sm tw-text-denim-900"
      >
        <thead class="tw-sticky tw-top-0 tw-z-10">
          <tr class="tw-bg-denim-100 tw-font-bold">
            <th class="tw-w-full tw-px-4 tw-pb-3 tw-pt-3.5 tw-text-left">
              {{ getLocaleText("syndications.source") }}
            </th>
            <th class="tw-px-4">
              <StreemTooltip
                anchor="top middle"
                self="bottom middle"
              >
                {{ getLocaleText("syndications.potential_audience_reach") }}
              </StreemTooltip>
              <BaseIcon
                :aria-label="
                  getLocaleText('syndications.potential_audience_reach')
                "
                class="tw-text-2xl tw-font-normal"
                icon="followers"
              />
            </th>
            <th
              v-if="canShowAdvertisingValue"
              class="tw-px-4"
            >
              <StreemTooltip
                anchor="top middle"
                self="bottom middle"
              >
                {{
                  getLocaleText("syndications.advertising_value_equivalency")
                }}
              </StreemTooltip>

              <AdvertisingValueIcon />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(syndicated, index) in syndications"
            :key="index"
            class="syndications__faux-border tw-relative"
            @click.stop="syndicationClicked(syndicated)"
          >
            <td class="tw-px-4 tw-py-1.5 tw-text-left">
              <span
                class="tw-line-clamp-1 hover:tw-underline"
                :title="syndicated.source_name"
                >{{ syndicated.source_name }}</span
              >
              <span
                class="tw-align-center -tw-mt-1 tw-flex tw-items-center tw-text-denim-600"
              >
                <SyndicationsPopperTick
                  :is-checked="hasMatchingSyndications(syndicated.id)"
                  :is-loading="state.isLoading"
                />
                <time :datetime="syndicated.timestamp.toString()">
                  {{ formatSyndicatedDate(syndicated) }}
                </time>
              </span>
            </td>
            <td>
              <span
                v-if="syndicated.audience"
                class="tw-px-4 tw-text-xs tw-font-medium"
              >
                {{ getLocaleNumber(syndicated.audience, "suffix") }}
              </span>

              <BaseIcon
                v-else
                class="tw-text-2xl tw-text-denim-500"
                icon="minus"
              />
            </td>

            <td v-if="canShowAdvertisingValue">
              <span
                v-if="syndicated.advertising_value"
                class="tw-px-4 tw-text-xs tw-font-medium"
              >
                {{ getLocaleNumber(syndicated.advertising_value, "suffix") }}
              </span>

              <BaseIcon
                v-else
                class="tw-text-2xl tw-text-denim-500"
                icon="minus"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Popper>
</template>

<script setup lang="ts">
import { Notify, Screen } from "quasar";
import { computed, inject, onBeforeUnmount, reactive } from "vue";

import { getLocaleNumber, getLocaleText } from "shared/boot/i18n";
import AdvertisingValueIcon from "shared/components/AdvertisingValueIcon.vue";
import { StreemTooltip } from "shared/components/base";
import BaseIcon from "shared/components/base/BaseIcon.vue";
import Popper, { type PopperProps } from "shared/components/base/Popper.vue";
import useStreamFiltering from "shared/composables/useStreamFiltering";
import {
  dateInTimeZone,
  formatDate,
  parseDate,
  shortTimezone,
} from "shared/helpers/date";
import { sortedSyndications } from "shared/helpers/mentions";
import features from "shared/services/features";
import MatchingSyndications from "shared/services/fetching/matchingSyndications";
import type { Stream } from "shared/types";
import type {
  MentionWithSyndication,
  Syndication,
  TvCaptionSyndication,
} from "shared/types/mentions";
import { isTvMention, isTvOrRadioMention } from "shared/types/mentions/guards";

import { SyndicationsPopperTick } from ".";

const isMobile = inject("isMobile");
const SYNDICATION_THRESHOLD_VALUES = [0, 0.25, 0.5, 0.75, 1];
const OPEN_DELAY = 250;

export type SyndicationPopperProps = {
  mention: MentionWithSyndication;
  parentElRef?: HTMLElement | null;
  stream: Stream;
};

type State = {
  isLoading: boolean;
  isOpen: boolean;
  observer: IntersectionObserver | null;
  openTimestamp: number;
  syndicationsMatchingStream: Syndication[] | null;
};

const props = withDefaults(defineProps<SyndicationPopperProps>(), {
  parentElRef: null,
});

const emit = defineEmits<{
  (e: "syndication-clicked", syndication: Syndication): void;
}>();

const state = reactive<State>({
  isLoading: false,
  isOpen: false,
  observer: null,
  openTimestamp: 0,
  syndicationsMatchingStream: null,
});

const isTv = computed(() => isTvMention(props.mention));
const syndications = computed(() => sortedSyndications(props.mention));

const canShowAdvertisingValue = computed(() =>
  features.has("advertising_value_mentions")
);

const { filters, filtering } = useStreamFiltering({ stream: props.stream });

const hasMatchingSyndications = computed(
  () => (id: number) => Boolean(state.syndicationsMatchingStream?.[id])
);

const popperAttrs = computed<PopperProps>(() => ({
  "click-outside-capture": true,
  disableClickOutside: false,
  offset: [0, isMobile ? 0 : 32],
  placement: isMobile ? "bottom" : "right",
  fallbackPlacements: ["left"],
  flip: true,
  tether: true,
  width: isMobile ? Math.min(Screen.width, 375) : 350,
}));

const formatSyndicatedDate = (syndicated: Syndication) => {
  const timestamp = isTvOrRadioMention(props.mention)
    ? (syndicated as TvCaptionSyndication).start_time
    : syndicated.published_at;

  if (!syndicated.source) return formatDate(new Date(timestamp), "h:mmaaa");

  const timezone = shortTimezone(
    parseDate(timestamp),
    syndicated.source.time_zone
  );

  const timeString = dateInTimeZone(
    timestamp,
    "h:mmaaa",
    syndicated.source.time_zone
  );

  return `${timeString} ${timezone}`;
};

const syndicationClicked = (syndication: Syndication) => {
  const emitPayload = {
    ...syndication,
    type: props.mention.type,
  } as Syndication;

  if (isTv.value) {
    emitPayload.excerpts = props.mention.excerpts;
  }

  emit("syndication-clicked", emitPayload);
  state.isOpen = false;
};

const loadSyndicationMatches = async () => {
  const { stream, mention } = props;

  try {
    if (!state.isLoading && stream?.id && !state.syndicationsMatchingStream) {
      state.isLoading = true;

      const response = await MatchingSyndications.get({
        stream,
        mention,
        options: filtering.value ? filters.value.requestFilters() : {},
      });

      state.syndicationsMatchingStream = response.syndications_matching_stream;
    }
  } catch {
    Notify.create(getLocaleText("syndications.unable_to_load_syndications"));
  } finally {
    state.isLoading = false;
  }
};

const destroyObserver = () => {
  if (props.parentElRef && state.observer) {
    state.observer.unobserve(props.parentElRef);
    state.observer.disconnect();
    state.observer = null;
  }
};

const createObserver = () => {
  if (props.parentElRef) {
    state.observer = new IntersectionObserver(
      (entries) => {
        const timeSinceOpen = Date.now() - state.openTimestamp;

        if (entries[0].intersectionRatio < 1 && timeSinceOpen > OPEN_DELAY) {
          state.isOpen = false;
          destroyObserver();
        }
      },
      { threshold: SYNDICATION_THRESHOLD_VALUES }
    );

    state.observer.observe(props.parentElRef);
  }
};

onBeforeUnmount(() => destroyObserver());

const toggleOpen = async () => {
  state.isOpen = !state.isOpen;

  if (state.isOpen) {
    state.openTimestamp = Date.now();
    createObserver();
    await loadSyndicationMatches();
  } else {
    destroyObserver();
  }
};

defineExpose({ state });
</script>

<style lang="scss" scoped>
.syndications__faux-border:not(:last-child)::after {
  background: var(--s-color-denim-3);
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 1rem;
  position: absolute;
  width: calc(100% - 2rem);
}
</style>
